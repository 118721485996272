import React from 'react';

const ServicioAlUsuario = () => (
  <div className="p-4 flex">
    <div className="w-1/2 pr-4">
      <h2 className="text-2xl font-bold mb-4">Servicio al Usuario</h2>
      <p>Estamos aquí para ayudarte con cualquier duda o consulta que puedas tener. Nuestro equipo de soporte está disponible 24/7.</p>
    </div>
    <div className="w-1/2">
      <img src="https://i.ibb.co/sHB4rWK/office-employee-with-ai-generated-free-png.png" alt="Office Employee" className="shadow" />
    </div>
  </div>
);

export default ServicioAlUsuario;