import React from 'react';

const QuienesSomos = () => (
  <div className="p-4 flex justify-between">
    <div>
      <h2 className="text-2xl font-bold mb-4">Quiénes Somos</h2>
      <p>Somos una propuesta universitaria dedicada al mejoramiento laboral y profesional de nuestros clientes.</p>
      <p>Nuestra misión es proporcionar las herramientas y conocimientos necesarios para que los profesionales y empresas puedan alcanzar su máximo potencial.</p>
    </div>
    <div className="w-1/2">
      <img src="https://i.ibb.co/Jdmg8DM/office-employee-with-ai-generated-free-png-3.png" alt="Imagen de un empleado de oficina" className="w-full shadow" />
    </div>
  </div>
);

export default QuienesSomos;
